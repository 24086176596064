@import "_reset";

$padding: 1em;


// https://coolors.co/1a659e-ff5858-fcfcfc-8fc93a-e4cc37
$bg: #343434;
$color-text: #212121;
$color-blue: #1a659e;
$color-red: #FF5858;
$color-green: #8fc93a;
$color-yellow: #e4cc37;

$golden-ratio: 1.618;
$font-scale: $golden-ratio;

/**
 * Return font size in em from $font scale
 */
@function get_font($exponents) {
  $raised: 1;

  @for $i from 1 through $exponents {
    $raised: $raised * $font-scale;
  }

  @return $raised + em;
}

*, *:before, *:after {
  box-sizing: border-box;
}
html, body {
  font-family: 'Raleway', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.36;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased
}
*, *:before, *:after {
  box-sizing: inherit;
}
img {
  max-width: 100%;
  height: auto;
}

h1, h2 {
  font-size: get_font(1);
}
h3, h4, h5, h6 {
  font-size: 1em;
}

h1, h2, h3,
h4, h5, h6 {
  line-height: 1.25;
  margin: 0 0 0.618em 0;
  font-weight: 700;
  letter-spacing: -0.01em;

  span {
    font-weight: 100;
  }
}

p {
  margin-bottom: 1.618em;
}

a {
  color: lighten($color-blue, 10%);
  text-decoration: underline;
  padding: 1em 0;

  &:hover {
    color: lighten($color-blue, 20%);
  }
}

a {
  transition: all ease-out 0.42s;
}

.text-center {
  text-align: center;
}

/* Preloader */
.preloader {
  z-index: 1; // Fix auto z-index bug
}

/* Buttons and CTA */
.btn {
  display: inline-block;
  padding: 1em;
  border-radius: 0.236em;
  background: $color-blue;
  border: none;
  font-size: inherit;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.cta {
  display: table;
  margin: 0 auto;
  animation: fadein 1.2s ease-in;

  > span {
    display: inline-block;
    vertical-align: middle;
    padding: 0.236em;
  }

  .social {
    background: $color-blue;
    padding: 0;
    border-radius: 50%;
    font-size: get_font(2);
    display: block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    color: #fff;
    box-sizing: content-box;
    padding: 0.236em;
  }
}

.social, .btn {
  &:hover {
    background: lighten($color-blue, 10%);
    color: #fff;
  }
}

/* Screens */
#content {
  opacity: 1;
  animation: fadein 0.7s ease-in;
  z-index: 2;
}
.screen, #content, .screen-hero {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.screen {
  position: absolute;
  z-index: 2;
  background: $bg;
  display: table;
  height: 100%;

  > div {
    padding: 0 1em 38.22% 1em;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
}

.max-width {
  max-width: 900px;
  margin: 0 auto;
}

/* Menu */
#main-menu {
  position: absolute;
  font-size: get_font(1);
  padding: 0.618em;
  right: 0;
  top: 0;
  z-index: 11;
  opacity: 0.7;
  cursor: pointer;
  line-height: 0;

  &:hover {
    opacity: 1;
    transition: all ease-out 0.42s;
  }
}

#main-menu-dropdown {
  z-index: 10;
  text-align: center;
  background-color: rgba($color-blue, 0.98);

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 1.618em;
    line-height: 0.618em;
    transition: color 0.3s ease-out;

    &.active {
      text-decoration: underline;
    }

    &:hover {
      color: lighten($color-blue, 45%);
    }
  }
}

/* Hero */
#hero {
  position: absolute;
  right: 0.618em;
  bottom: -4.236em;
  height: 40%;
  max-height: 960px;
  min-height: 180px;
  width: auto;
  z-index: 12;
  animation: fly-left 1.4s ease-out;
}

.hero-text {
  position: absolute;
  z-index: 12;
  max-width: get_font(6);
  right: 1.618em;
  bottom: 0;
  margin: 0.618em get_font(4) 0.618em 0.618em;
  background: rgba(#fff, 0.87);
  padding: 1em;
  border-radius: 1em;
  color: $color-text;
  font-weight: bold;
  text-align: center;
  transform-origin: center right;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 5;
    bottom: 1.618em;
    right: -2em;
    border-style: solid;
    border-width: 0 0 1em 2em;
    border-color: transparent rgba(#fff, 0.87);
  }
}

/* Skill */
.skill {
  display: inline-block;
  width: 1.618em;
  background: rgba(#000, 0.3);
  margin: 0.236em;
  overflow: hidden;
  z-index: 2;
  position: relative;
  border-radius: 0.618em;
  color: $color-text;

  > .exp {
    background: $color-red;
    display: block;
    border-top: 2px solid rgba(#fff, 0.1);
    transform: translateZ(0px); // Fix transation/scale bug
    border-radius: 0.618em;
    animation: scale-y 2.12s ease-in-out;
    transform-origin: bottom center;
  }

  > .text {
    transform: rotate(-90deg);
    transform-origin: left top;
    padding-left: 0.618em;
    height: 1.618em;
    line-height: 1.618em;
    white-space: nowrap;
  }

  &:nth-child(2n+1) {
    > .exp {
      background: $color-green;
    }
  }

  &:nth-child(3n+1) {
    > .exp {
      background: $color-yellow;
    }
  }

  &:nth-child(4n) {
    > .exp {
      background: lighten($color-blue, 15%);
    }
  }
}

/* About Me */
#about {
  .face {
    border-radius: 50%;
    border: 2px solid lighten($bg, 10%);
  }
  .desc {
    height: get_font(5);
    display: table;
    margin: 0 auto;

    > div {
      display: table-cell;
      vertical-align: middle;
    }

    p {
      max-width: 546px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#timeline {
  border-top: 3px solid rgba($color-yellow, 0.1);
  display: inline-block;
  margin-top: 1em;
  white-space: nowrap;

  .year {
    font-weight: bold;
    color: $bg;
    width: get_font(2);
    height: get_font(2);
    line-height: get_font(2);
    border-radius: 50%;
    background: rgba($color-blue, 0.4);
    margin: 1em 0.618em;
    position: relative;
    top: -2.42em;
    color: #fff;
    cursor: pointer;
    display: none;
    transition: all 450ms ease;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    &.next {
      transform: scale(1.2);
      background: rgba($color-blue, 0.65);
    }
    &.prev, &.next, &.next+.year {
      display: inline-block;
    }

    &.done {
      background: $bg;
    }

    &:hover {
      background: $color-blue;
    }

    &.active {
      display: inline-block;
      background: $color-red;
      transform: scale(1.618);
    }
  }
}

/* Tablet */
@media only screen and (min-width: 768px) {
  @import "_tablet";
}

/* Desktop */
@media only screen and (min-width: 992px) {
  @import "_desktop";
}

/* Wide */
@media only screen and (min-width: 1200px) {
  @import "_wide";
}

/* Animation */
// Main screens
.anim-main-enter {
  animation: flyin 0.5s ease-out;

  &.anim-main-enter-active {
    opacity: 1;
  }
}

.anim-main-leave {
  animation: flyout 0.7s ease-out;
  opacity: 1;

  .anim-main-leave-active {
    opacity: 0;
  }
}

.anim-fade-enter {
  opacity: 0;
  transition: opacity 300ms ease-out;

  &.anim-fade-enter-active {
    opacity: 1;
  }
}


.anim-fade-leave {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

.anim-quote-enter {
  animation: flyin 450ms ease;
  transform-origin: bottom center;

  p {
    animation: flyin 850ms ease;
  }

  & + .anim-quote-enter {
    display: none!important;
  }
}

.anim-quote-leave {
  display: none!important;
}

.anim-talk-enter {
  animation: flyin 1s ease;

  &.anim-talk-enter-active {
    opacity: 1;
  }
}

.anim-talk-leave {
  animation: fadeout-talk 1s ease;

  .anim-talk-leave-active {
    opacity: 0;
    transform: scale(0.3);
    bottom: -1.618em;
  }
}


@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout-talk {
  from {
    opacity: 1;
  }
  50% {
    bottom: -1.618em;
  }
  to {
    opacity: 0;
    transform: scale(0.3);
  }
}

@keyframes flyin {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes flyout {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fly-left {
  from {
    right: -40%;
  }
  to {
    margin-right: auto;
  }
}

@keyframes scale-y {
  from {
    transform: scaleY(0);
  }
  70% {
    transform: scaleY(1.02);
  }
  to {
    transform: scaleY(1);
  }
}
