html, body {
  font-size: 18px;
}

/* Hero */
.hero-text {
  margin-right: get_font(5);
}

/* About Me */
#timeline {
  .year {
    display: inline-block;

    &:first-child {
      margin-right: get_font(2);
    }
  }
}