html, body {
  font-size: 16px;
}

h1, h2 {
  font-size: get_font(2);
  margin-bottom: 0.382em;
  line-height: 1.25;
}

/* Screens */
.screen {
  > div {
    padding-bottom: 4.236em;
  }
}

/* Hero */
#hero {
  bottom: 0.618em;
}
.hero-text {
  margin: 1em get_font(6) get_font(3) 1em;
}

.medium-br {
  display: block;
}
